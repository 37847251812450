<template>
  <form class="address-form" @submit.prevent="nextStep" autocomplete="off">
    <small class="text-transition d-block mb-3">
      <em>{{ $t('spot.postcodeObs') }}</em>
    </small>
    <b-row class="mx-0">
      <b-col cols="12" class="pl-0">
        <b-form-group label="Endereços Favoritos" label-for="favAddress" size="sm">
          <b-form-select
            id="favAddress"
            size="sm"
            v-model="favoriteAddress"
            @change="onChooseFavorite"
            :options="addresses"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mx-0">
      <b-col cols="6" class="px-0">
        <b-form-group
          :label="`${$t('orderEditModals.postcode')}:` | upperCase"
          label-for="postcode"
          size="sm"
          :state="validPostcode"
          :invalid-feedback="textPostcode"
        >
          <b-form-input
            v-model="postcode"
            :placeholder="$t('spot.placeholders.postcode')"
            v-bind:class="{ hasError: validPostcode }"
            v-mask="'#####-###'"
            :state="validPostcode"
            size="sm"
            id="postcode"
            type="text"
            @blur="updatePostcode"
            required
          />
        </b-form-group>
      </b-col>
      <b-col cols="6" class="pr-0">
        <b-form-group
          :label="`${$t('orderEditModals.number')}:` | capitalize"
          label-for="number"
          size="sm"
        >
          <b-form-input
            v-model="number"
            :placeholder="$t('spot.placeholders.number')"
            size="sm"
            id="number"
            type="number"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mx-0">
      <b-col cols="6" class="pl-0">
        <b-form-group
          :label="`${$t('orderEditModals.neighborhood')}:` | capitalize"
          label-for="neighborhood"
          size="sm"
        >
          <b-form-input
            v-model="neighborhood"
            :placeholder="$t('spot.placeholders.neighborhood')"
            required
            size="sm"
            id="neighborhood"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6" class="pr-0">
        <b-form-group
          :label="`${$t('orderEditModals.city')}:` | capitalize"
          label-for="city"
          size="sm"
        >
          <b-form-input
            required
            v-model="city"
            :placeholder="$t('spot.placeholders.city')"
            size="sm"
            id="city"
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mx-0">
      <b-col cols="6" class="pl-0">
        <b-form-group
          :label="`${$t('orderEditModals.complement')}:` | capitalize"
          label-for="complement"
          size="sm"
        >
          <b-form-input
            v-model="complement"
            :placeholder="$t('spot.placeholders.complement')"
            size="sm"
            id="neighborhood"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6" class="pr-0">
        <b-form-group :label="`${$t('orderEditModals.uf')}:` | upperCase" label-for="uf" size="sm">
          <b-form-input
            v-model="uf"
            :placeholder="$t('spot.placeholders.city')"
            size="sm"
            id="uf"
            required
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mx-0">
      <b-col cols="12" class="px-0">
        <b-form-group
          :label="`${$t('orderEditModals.addressSimplified')}:` | capitalize"
          label-for="address"
          size="sm"
        >
          <b-form-input
            v-model="address"
            :placeholder="$t('spot.placeholders.address')"
            required
            size="sm"
            id="address"
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mx-0">
      <b-col cols="12" class="px-0">
        <b-form-group :label="`${$t('spot.addressObs')}:`" label-for="reference" size="sm">
          <b-form-textarea
            v-model="reference"
            rows="3"
            :placeholder="$t('spot.placeholders.reference')"
            size="sm"
            id="reference"
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end my-2">
      <b-button
        class="next-button"
        :disabled="geolocationIsActive || !validPostcode"
        variant="primary"
        size="sm"
        type="submit"
      >
        <span v-if="!loading">{{ $t(`spot.${isLast ? 'addButton' : 'nextButton'}`) }}</span>
        <icon v-else icon="loading" :size="20" color="#fff" spin />
      </b-button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import api from '@/services/api';
import viacep from '@/services/viacep';

export default {
  name: 'address-form',
  props: {
    isLast: {
      type: Boolean,
      default: () => false,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      validPostcode: null,
      textPostcode: '',

      address: '',
      number: '',
      complement: '',
      reference: '',
      postcode: '',
      neighborhood: '',
      city: '',
      uf: '',
      geolocation: {
        latitude: 0,
        longitude: 0,
      },

      favoriteAddress: 0,
      allAddresses: [],
      addresses: [],
    };
  },
  created() {
    this.setData();
    this.getFavoriteAddresses();
  },
  watch: {
    // postcode(value) {
    //   if (value.length === 9) {
    //     this.getAddress(value);
    //   }
    // },
  },
  computed: {
    ...mapGetters({
      getChosenOperation: types.GETTER_CHOSEN_OPERATION,
    }),
    geolocationIsActive() {
      return !(
        this.address &&
        this.number &&
        this.postcode &&
        this.neighborhood &&
        this.city &&
        this.uf
      );
    },
    form() {
      return {
        address: this.address,
        number: this.number,
        complement: this.complement,
        reference: this.reference,
        postcode: this.postcode.replace(/\D/g, ''),
        neighborhood: this.neighborhood,
        city: this.city,
        uf: this.uf,
        geoLocation: this.geolocation,
      };
    },
  },
  methods: {
    async getFavoriteAddresses() {
      this.allAddresses = [];
      this.addresses = [
        {
          value: 0,
          text: 'Não utilizar um endereço favorito',
        },
      ];

      const { data } = await api.get('/partner/address');

      if (data.success) {
        this.allAddresses = data.data;
      }

      this.allAddresses.forEach(address => {
        this.addresses.push({
          value: address.id,
          text: address.name,
        });
      });
    },
    async updatePostcode() {
      await this.checkPostcode();

      if (this.validPostcode) {
        await this.getAddress();
      }
    },
    async getAddress() {
      if (this.postcode.length === 9) {
        try {
          const postcode = this.postcode.replace(/-/g, '');
          const { data } = await viacep.get(`/${postcode}/json`);

          this.address = data.logradouro;
          this.uf = data.uf;
          this.neighborhood = data.bairro;
          this.city = data.localidade;
        } catch (error) {
          this.$toast.error('CEP inválido');
        }
      }
    },
    async checkPostcode() {
      if (this.postcode.length === 9) {
        try {
          const details = { ...this.getChosenOperation };
          details.postcode = this.postcode.replace(/-/g, '');
          const { data } = await api.post('/order/check-postcode', details);

          if (!data.data.allowed) {
            this.textPostcode = 'CEP fora de Área';
            this.$toast.error('CEP fora de Área');
          }

          this.validPostcode = data.data.allowed;
        } catch (error) {
          this.$toast.error('CEP fora de Área');
        }
      }
    },
    async getGeoLocation() {
      this.loading = true;
      const sendAddress = `${this.address},${this.number}-${this.neighborhood}-${this.city}-${this.uf}-${this.postcode}`;

      try {
        const { data: response } = await api.post('/orders/geolocation', { address: sendAddress });

        this.geolocation = {
          latitude: response.data.LATITUDE,
          longitude: response.data.LONGITUDE,
        };

        // this.$toast.success(this.$t('messages.getGeolocationSuccess'));
      } catch (error) {
        throw new Error('error');
      }

      this.loading = false;
    },
    async nextStep() {
      this.loading = true;
      try {
        await this.getGeoLocation();
        this.$emit('address-changed', this.form);
      } catch (error) {
        this.$toast.error(this.$t('messages.getGeolocationError'));
      }
      this.loading = false;
      this.scrollTopOrders();
    },
    setData() {
      Object.entries(this.data).forEach(([key, value]) => {
        this[key] = value;
      });
    },
    scrollTopOrders() {
      const scrollBox = document.querySelector('.stepper-scroll-bar');
      scrollBox.scrollTop = this.isLast ? 0 : 1000;
    },
    onChooseFavorite() {
      if (this.favoriteAddress === 0) {
        this.address = '';
        this.number = '';
        this.complement = '';
        this.reference = '';
        this.postcode = '';
        this.neighborhood = '';
        this.city = '';
        this.uf = '';
        return;
      }

      let address = [];

      this.allAddresses.forEach(item => {
        if (item.id === this.favoriteAddress) {
          address = item.detail;
        }
      });

      this.address = address.address;
      this.number = address.number;
      this.complement = address.complement;
      this.reference = '';
      this.postcode = `${address.postcode}`.replace(/(\d*)(\d{3})/, '$1-$2');
      this.neighborhood = address.neighborhood;
      this.city = address.city;
      this.uf = address.state;

      this.checkPostcode();
    },
  },
};
</script>

<style lang="scss">
.address-form {
  label {
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--dark-darken);
  }

  textarea {
    resize: none;
  }

  input,
  textarea {
    &::placeholder {
      color: var(--transition);
    }
  }

  .next-button {
    min-width: 76px;
  }
}
</style>
